.spaceship {
    transform: scale(1.2);
    animation: teetertotter 20s linear infinite;
    width: 100px;
    height: 75px;
    position: relative;

    .helmet {
        background-color: lighten(rgba(50, 215, 215, 0.60), 35%);
        border-radius: 50% 50% 0 0;
        width: 40%;
        height: 40%;
        animation: oscillate 12s linear infinite;
    }
    .cargo {
        box-shadow: 0 0 25px 5px #ccf;
        width: 90%;
        background-color: rgb(0, 255, 255);
        height: 5%;
        margin: auto;
    }
    .magnet {
        width: 60%;
        background-color: rgba(110, 165, 165, 0.2);
        height: 20%;
        border-radius: 0 0 50% 50%;
        margin: auto;
    }
}

@keyframes oscillate {
    0% {transform: translate(30%)}
    50% {transform: translate(120%)}
    100% {transform: translate(30%)}
}

@keyframes teetertotter {
    0%{transform: rotate(-10deg);}
    50%{transform: rotate(8deg);}
    100%{transform: rotate(-10deg);}
}