#root {
    height: 100%;
    width: 100%;
    margin: 0;
}

.App {
    text-align: center;
    /* background-color: darkred; */
    height: 100%;
    width: 100%;
    color: lightblue;
    display: flex;
    flex-flow: column;
}

h1 {
    font-size: 40px;
    margin: 0;
}

.title {
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-style: normal;
}

label {
    display: block;
    width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.copyright {
    position: absolute;
    bottom: 50px;
}
